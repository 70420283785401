import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import React from 'react'
import { DownloadIcon } from 'src/assets/DownloadIcon'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'

import pdf from '../../../static/institucional/politica_de_privacidade.pdf'
import { storeUrl } from '../../../store.config'

import '../../styles/pages/privacidade.scss'

const Privacy = () => {
  const { privacyBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Política de Privacidade"
      banners={privacyBanners}
    >
      <div className="privacy-container">
        <p>
          <strong>IRMÃOS BOA LTDA.</strong>, com sede no Estado de São Paulo/SP,
          na Cidade de Jundiaí, na Rua do Retiro, nº 925, Vila Virgínia, CEP:
          13.209-000, inscrito no CNPJ/MF sob o nº 50.948.371/0016-54,
          (“Lojista”), empresa que disponibiliza o Programa de Descontos e
          Fidelidade “Dom Olívio” (“Programa”), aos clientes que realizem
          compras em seus estabelecimentos (“Usuário” ou “Usuários”), vem, por
          intermédio desta Política de Privacidade (“Política”), apresentar aos
          seus Usuários as informações essenciais sobre a forma que os seus
          dados serão utilizados e tratados.
        </p>
        <p>
          <b>
            A coleta, o uso e o compartilhamento dos dados pessoais dos Usuários
            do Lojista ficarão sujeitos a esta Política de Privacidade e suas
            atualizações. Ao acessar o site{' '}
            <a href={storeUrl} target="_blank" rel="noreferrer">
              {storeUrl}
            </a>{' '}
            ou Aplicativo, o Usuário deverá ler a presente Política,
            manifestando sua concordância com relação às suas disposições, por
            meio do botão disponibilizado para tanto. Por meio de tal ato, o
            Usuário demonstra sua manifestação livre, expressa, informada e
            inequívoca, sem quaisquer reservas, acerca de todo o conteúdo deste
            documento, nos termos da Lei Geral de Proteção de Dados (Lei nº
            13.709/2018).
          </b>
        </p>
        <p>
          Para entrar em contato com o Lojista, basta enviar um e-mail para:{' '}
          <a href="mailto: dpo@smboa.com.br">dpo@smboa.com.br</a>
        </p>
        <p>
          <a
            href={pdf}
            download="politica_de_privacidade.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Privacy
